export const data = {
    "about": [
        "Powerplay helps you get better visibility of your projects regularly and avoid project delays. Save your time and money by having better control over your projects.",

        "Contractors enable their project teams to manage 30% more construction activities and jobs effectively than other means",

        "Over 80% of users realized cost savings of 6 to 12%, which is worth 1.2 Billion Dollars",

        "More than 60% of projects were completed on time, saving an average of 12 days on their projects"
    ],
    "features": [
        ["Daily Log", "Keep track of all details including labor, equipments, & materials."],

        ["Photos", "Capture photos from mobile & attach to project progress reports for seamless documentation."],

        ["Task Management", "Track, assign & report field tasks in one place."],

        ["Collaboration", "Communicate with your crew & customers in real-time."],

        ["Labor Management", "Track Daily attendance across office & site teams."],

        ["Material Management", "Manage material lists, track requests, and movement of materials."],

        ["Reports", "Generate Daily Progress Reports in just a few minutes without hassle."],

        ["Unlimited Storage", "Access all site data like POs, invoices, reports, and many more without storage issues."]
    ]
}