import React, { useCallback, useState, useEffect } from 'react';

import toast, {Toaster} from 'react-hot-toast';
import { StaticImage } from 'gatsby-plugin-image';
import Header from '../components/Header';
import Footer from '../components/Footer';
import apiClient from '../utils/apiClient';
import { mobileCheck } from '../utils/mobileCheck';
import { RESPONSE_CODES } from '../constants';
import { data } from '../data/demo_shedule';
import '../styles/styles.scss';
import Footer2 from '../components/Footer2';

const RequestDemo = () => {
  const [demoSuccess, setDemoSuccess] = useState('');

  const [demoError, setDemoError] = useState('');
  
  const [isMobile, setIsMobile] = useState(false);

  const { about , features } = data;
 
  useEffect(() => {
    const mobile = mobileCheck();
    setIsMobile(mobile);
  }, []);

  useEffect(() => {
    if (demoSuccess) {
      const timeout = setTimeout(() => {
        setDemoSuccess('');
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {};
  }, [demoSuccess]);

  useEffect(() => {
    if (demoError) {
      const timeout = setTimeout(() => {
        setDemoError('');
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {};
  }, [demoError]);

  const [input, setInput] = useState({
    name: '',
    phone: '',
    email: '',
    companyName: '',
    companyType: 'General Contractor',
    companySize: '0 - $2M',
  });

  const inputChangeHandler = useCallback((e) => {
    const { name, value } = e.target;

    setInput((state) => ({ ...state, [name]: value }));
  }, []);

  const onSubmit = (input, callback) => {
    (async () => {
      const body = {
        ...input,
        custom_field: {
          cf_company_name: input.companyName,
          cf_company_size: input.companySize,
          cf_company_type: input.companyType,
        },
      };
      try {
        await apiClient.post('/request-demo', { contact: body });
        toast.success(
          <span className="toast">
            Your demo request is submitted successfully.
          </span>,
        );
        callback();
      } catch (error) {
        const errorText = (error?.response?.status === RESPONSE_CODES.BAD_REQUEST) ? 'You have already requested for the demo' : 'Something went wrong! Please try again later...';
        toast.error(<span className="toast">{errorText}</span>);
      }
    })();
  };

  const submitHandler = (e) => {
      e.preventDefault();
      onSubmit(input, () =>
        setInput({
          name: '',
          phone: '',
          email: '',
          companyName: '',
          companyType: 'General Contractor',
          companySize: '0 - $2M',
        })
      );
  };



  return (
    <div>
      <Header />
      <section>
        <form className="request-demo-form" onSubmit={submitHandler}>
          <div className="request-demo-container">
            <div className="flex-row-1 request-demo-row-1">
            <div className="request-demo-hero">
              <h3 className="request-demo-hero-heading">Globally acclaimed<span><br /> #1 Construction Management Software and App</span></h3>
              <StaticImage src="../images/powerplay-desktop-request.webp" loading='lazy' placeholder='blurred' alt='request demo' />
            </div>
            </div>
            <div className="flex-row-2 request-demo-row-2">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="request-demo-name">
                    Name*
                    <input
                      id="request-demo-name"
                      type="text"
                      name="name"
                      placeholder="Enter Full Name"
                      value={input.name}
                      onChange={inputChangeHandler}
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="col col-flex">
              <div className="form-group one">
                  <label htmlFor="request-demo-email">
                    Email*
                    <input
                      id="request-demo-email"
                      type="email"
                      name="email"
                      placeholder="name@company.com"
                      value={input.email}
                      onChange={inputChangeHandler}
                      required
                    />
                  </label>
                </div>
                <div className="form-group two">
                  <label htmlFor="request-demo-phone">
                    Phone*
                    <input
                      id="request-demo-phone"
                      name="phone"
                      type="text"
                      placeholder="+1 9999988888"
                      value={input.phone}
                      onChange={inputChangeHandler}
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="col col-flex">
                <div className="form-group one">
                  <label htmlFor="request-demo-company">
                    Company Name*
                    <input
                      id="request-demo-company"
                      type="text"
                      name="companyName"
                      placeholder="Enter Company Name"
                      value={input.companyName}
                      onChange={inputChangeHandler}
                      required
                    />
                  </label>
                </div>
                <div className="form-group two">
                  <label htmlFor="request-demo-companytype">
                    Company Type*
                    <select
                      id="request-demo-companytype"
                      name="companyType"
                      value={input.companyType}
                      onChange={inputChangeHandler}
                      required>
                      {[
                        'General Contractor',
                        'Subcontractor',
                        'Owner or Developer',
                        'Government',
                        'Others',
                      ].map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="request-demo-companysize">
                    Avg. Annual Construction Volume?
                    <select
                      id="request-demo-companysize"
                      name="companySize"
                      value={input.companySize}
                      onChange={inputChangeHandler}
                      required>
                      {['$0 - $2M', '$2 - $5M', '$5 - $10M', '$10 - $20M', '$20M+'].map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <button type="submit" className="button">
                    Request a Demo
                  </button>
                </div>
                {demoSuccess && (
                  <small className="success-message">{demoSuccess}</small>
                )}
                {demoError && <small className="error-message">{demoError}</small>}
              </div>
            </div>
          </div>
          <div className="about-powerplay">
            <h3 className="about-powerplay-heading">Why Powerplay?</h3>
            <div className="about-powerplay-body">
              <p className="about-powerplay-description">
                Fastest & Simplest way to manage your projects from the palm of your hand.<br />
                Say goodbye to Whatsapp and Excel Sheets. Powerplay helps you manage multiple projects simultaneously on a single platform.<br />
                Easy collaboration keeps everyone in the loop, preventing paperwork, manual entry error, maintaining historical records for dispute resolution and miscommunication. No sweat, just timely actions. This Site Management App makes communication and collaboration effective and transparent. This means your business can operate the way construction really works-in teams.
              </p>
              <div className="about-powerplay-benefits">
                <ul>
                    {about.map((curr) => {
                      return (
                        <li><p className="list-element">{curr}</p></li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </form>
        <div className="request-demo-content">
          <div className="features-component">
            <StaticImage src="../images/worker-desktop.webp" loading='lazy' placeholder="blurred" alt="feature-worker" />
            <div className="features-powerplay">
              <h3 className="feature-heading">
                One Platform,<br />
                <span>Effective Communication</span><br />
                and Effortless Transparency.
              </h3>
              <p>Organize all your project details and tasks, the one solution that contractor needs</p>
              <h3 className="key-feature">Key Features:</h3>
              <div className="feature-list">
                <ul>
                  {features.map((curr) => {
                    return (
                      <li><p className="feature-element"><span>{`${curr[0]}: `}</span>{curr[1]}</p></li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      <Footer />
      <Footer2/>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};

export default RequestDemo;
